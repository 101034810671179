<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">

					<el-col :span="6">
						<el-form-item label="用户账号">
							<el-input v-model="searchParm.accountNo" placeholder="用户ID/账号/手机/姓名"></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="6">
						<el-form-item label="所在城市">
							<el-cascader v-model="provinces" clearable placeholder="请选择省/市/区" @change="CityChange"
								:props="{ value: 'name', label: 'name', children: 'child' }" :options="cityOptions">
							</el-cascader>
						</el-form-item>
					</el-col>
					<el-col :span="10">
						<el-row>
							<el-form-item label="注册时间">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.regTimeStart" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;">
									<span>-</span>
								</el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.regTimeEnd" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column type="selection" align="center" width="55">
							</el-table-column>
							<el-table-column prop="accountNo" align="center" label="用户账号" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="mobile" align="center" label="手机" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="name" align="center" label="姓名" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="city" align="center" label="所在城市" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="regTime" align="center" label="注册时间" show-overflow-tooltip
								:formatter="(row, column)=>{
								let data = row.regTime;
									if (data ==='') {
											 return ''
										 }else {
											 let dt = new Date(data)
											 return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes() + ':' + dt.getSeconds()
										}
									}
							">
							</el-table-column>
							<el-table-column prop="status" align="center" label="账户启用状态">
								<template slot-scope="scope">
									<el-switch v-model="scope.row.status" active-color="#4A99F6"
										@change="changeSwitch($event,scope.row)" :active-value="1" :inactive-value="0">
									</el-switch>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">
										查看
									</el-button>
									<el-button type="text" @click="edit(scope.row)">
										编辑
									</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	import edit from "./edit.vue"
	import look from "./look.vue"
	export default {
		components: {
			smCard,
			edit,
			look
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
				// batchActionType: '',
				showEditDialog: false,
				showLookDialog: false,
				editObj: {},
				lookObj: {},
				cityOptions: [],
				provinces: []
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.provinces = [];
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/user-api/exp/user/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//打开查看弹窗
			look(row) {
				this.lookObj = {};
				this.lookObj["id"] = row.id;
				this.showLookDialog = true;
			},
			//打开编辑弹窗
			edit(row) {
				this.editObj = {};
				this.editObj["id"] = row.id;
				this.editObj["type"] = 1;
				this.showEditDialog = true;
			},
			//删除
			remove(row) {
				this.$confirm("是否确认删除？", "删除企业账户", {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post("/user-api/ent/user/delete", {
						id: row.id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
			//点击账户启用状态
			changeSwitch(val, row) {
				this.$post("/user-api/exp/user/status/update", {
					'id': row.id,
					'status': val
				}).then((res) => {
					if (res.code == 1000) {
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 获取省市区地址
			getProvince(e) {
				// 获取省市区地址
				this.$get("base-api/area/tree").then((data) => {
					this.cityOptions = this.getTreeData(data.data);
					if (e == 1) {
						this.$nextTick(() => {
							let i, j, k;
							for (i = 0; i < this.cityOptions.length; i++) {
								if (this.cityOptions[i].name === this.formData.province) {
									this.$set(this.provinces, 0, this.cityOptions[i].name);
									break;
								}
							}
							for (j = 0; j < this.cityOptions[i].child.length; j++) {
								if (this.cityOptions[i].child[j].name === this.formData.city) {
									this.$set(this.provinces, 1, this.cityOptions[i].child[j].name);
									break;
								}
							}
							for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
								if (
									this.cityOptions[i].child[j].child[k].name === this.formData.district
								) {
									this.$set(this.provinces, 2, this.cityOptions[i].child[j].child[k]
										.name);
									break;
								}
							}
						});
					}
				});
			},
			// 递归判断列表，把最后的children设为undefined
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].child.length < 1) {
						// children若为空数组，则将children设为undefined
						data[i].child = undefined;
					} else {
						// children若不为空数组，则继续 递归调用 本方法
						this.getTreeData(data[i].child);
					}
				}
				return data;
			},
			CityChange(value) {
				this.searchParm.province = value[0];
				this.searchParm.city = value[1];
				this.searchParm.district = value[2];
			},
		},
		mounted() {
			this.list();
			this.getProvince(0);
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss"
</style>
